html {
    --background_color: rgb(20, 20, 20);
    --background_gradient: linear-gradient(-180deg, rgb(20, 20, 20) 75%, rgb(53, 39, 85) 100%);
    --background_with_transparency: #141414d0;
    --text_color: #f2f2f2;
    --dimmed_text_color: #d3d3d3;
    --containers_color: #2f2f2f;
    --containers_color_transparency: #2f2f2f8a;
    --containers_color_hovered: #4e4e4e;
    --accent_color: #bd34fe;
    --accent_color_hover: #9f18dd;
    --complementary_accent_color: #FF964D;
    --title_gradient_origin: #93f5ec;
    --title_gradient_end: #a77bf3;
    --complementary_blue: #89b1ff;

    --red: #990b0b;
    --red_bright_desaturated: #f37676;
    --green: #0b9917;
    --language_selected: #b632f3;
    --language_unselected: #6e04a0;
    /*
    --language_selected: #baf8b2;
    --language_unselected: #324d2f;
*/

    --porfolio_gradient: linear-gradient(-45deg, #bd34fe 50%, #ff3670 50%);
    --porfolio_overlay_gradient: linear-gradient(0deg, rgba(20, 20, 20, 1) -10%, rgba(20, 20, 20, 0) 25%);
    --hero_overlay_gradient: linear-gradient(0deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0) 25%);
    --about_overlay_gradient: linear-gradient(0deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0) 20%, rgba(20, 20, 20, 0) 80%, rgba(20, 20, 20, 1) 100%);
    --porfolio_overlay_gradient_horizontal_left: linear-gradient(-90deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0) 100%);
    --porfolio_overlay_gradient_horizontal_right: linear-gradient(90deg, rgba(20, 20, 20, 1) 0%, rgba(20, 20, 20, 0) 100%);
    --porfolio_gradient_filter: blur(72px);
}